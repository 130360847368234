import { Inter, Poppins, Public_Sans } from 'next/font/google'

const interFont = Inter({
  subsets: ['latin'],
  weight: ['300', '400', '500', '600', '700', '800'],
  variable: '--font-inter'
})

const poppins = Poppins({
  subsets: ['latin'],
  weight: ['300', '400', '500'],
  variable: '--font-poppins'
})

const publicSans = Public_Sans({
  subsets: ['latin'],
  weight: ['300', '400', '500', '600', '700'],
  variable: '--font-public-sans'
})

export const fontClass = `${interFont.variable} ${poppins.variable} ${publicSans.variable}`
