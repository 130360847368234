import React from 'react'

const ENVIRONMENT = process.env.NEXT_PUBLIC_NODE_ENV

export default function DetectResponsive() {
  if (['stage', 'production'].includes(String(ENVIRONMENT).toLowerCase())) {
    return
  }

  return (
    <>
      <div className='fixed bottom-10 left-10 z-[999] h-16 w-16 overflow-hidden rounded-full font-bold text-white shadow-lg'>
        <div className='flex h-full w-full items-center justify-center bg-purple-500 text-center sm:hidden'>
          default:
        </div>
        <div className='hidden h-full w-full items-center justify-center bg-red-500 text-center sm:flex md:hidden'>
          sm:
        </div>
        <div className='hidden h-full w-full items-center justify-center bg-amber-500 text-center md:flex lg:hidden'>
          md:
        </div>
        <div className='hidden h-full w-full items-center justify-center bg-green-500 text-center lg:flex xl:hidden'>
          lg:
        </div>
        <div className='hidden h-full w-full items-center justify-center bg-primary text-center xl:flex 2xl:hidden'>
          xl:
        </div>
        <div className='hidden h-full w-full items-center justify-center bg-teal-500 text-center 2xl:flex'>2xl:</div>
      </div>
    </>
  )
}
