import {
  RESET_CUSTOMER_REDUCER,
  STORE_CUSTOMERS,
  STORE_PAYMENT_METHODS,
  STORE_SINGLE_CUSTOMER,
  STORE_SPLIT_PAYMENTS,
  STORE_TIPS
} from '../constants/actionTypes'
import { defaultPaymentMethodData } from '@/constants'
import DefaultValues from '@/constants/default-values/DefaultValues'

const initialState = {
  customers: [],
  selected_customer: null,
  selectedCustomer: { ...DefaultValues.ContactInfo },
  paymentMethods: defaultPaymentMethodData,
  splitPayments: {
    partial: false,
    split: false,
    pay_in: false,
    pay_in_value: '+4'
  },
  tips: {
    percent_1st: false,
    percent_2nd: false,
    custom: false,
    percent_1st_value: '5',
    percent_2nd_value: '10'
  },
  adjustmentValues: {
    debit_card: '0',
    credit_card: '0',
    bank_transfer: '0'
  }
}

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_CUSTOMERS:
      return {
        ...state,
        customers: action.payload
      }

    case STORE_SINGLE_CUSTOMER:
      return {
        ...state,
        selectedCustomer: action.payload
      }

    case STORE_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: action.payload
      }

    case STORE_SPLIT_PAYMENTS:
      return {
        ...state,
        splitPayments: action.payload
      }

    case STORE_TIPS:
      return {
        ...state,
        tips: action.payload
      }

    case RESET_CUSTOMER_REDUCER:
      return initialState

    default:
      return state
  }
}

export default customerReducer
