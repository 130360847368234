import CircularProgress from '@mui/material/CircularProgress'
import { cn } from '@/lib/utils'
import { SpinnerIcon } from '@/v1/icons'

interface Props {
  className?: string
  progressClassName?: string
}

const FallbackSpinner = ({ className, progressClassName }: Props) => {
  return (
    <div className={cn('mx-auto grid place-items-center', className)}>
      <SpinnerIcon />
      <CircularProgress disableShrink className={cn('mt-6', progressClassName)} />
    </div>
  )
}

export default FallbackSpinner
