import * as React from 'react'
import { SvgProps } from '@/types/global'

export function SliderPrevIcon(props: SvgProps) {
  return (
    <svg
      fill='none'
      stroke='#b5b5b5'
      strokeWidth={1.5}
      viewBox='0 0 24 24'
      className='h-12 w-12'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path strokeLinecap='round' strokeLinejoin='round' d='M15.75 19.5 8.25 12l7.5-7.5' />
    </svg>
  )
}

export function SliderNextIcon(props: SvgProps) {
  return (
    <svg
      fill='none'
      stroke='#b5b5b5'
      strokeWidth={1.5}
      viewBox='0 0 24 24'
      className='h-12 w-12'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path strokeLinecap='round' strokeLinejoin='round' d='m8.25 4.5 7.5 7.5-7.5 7.5' />
    </svg>
  )
}
