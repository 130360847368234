import Swal from 'sweetalert2'
import goToDirectory from '../custom-components/GoToDirectory'
import toast from 'react-hot-toast'
import Box from '@mui/material/Box'
import { Skeleton } from '@mui/material'
import { AES, enc } from 'crypto-js'
import secureLocalStorage from 'react-secure-storage'
import dayjs from 'dayjs'
import { nf2, rn } from '@/lib/utils'

/**
 *
 * @param res - the api response
 * @param returnUrl - after process response redirect in which page
 * @param defaultMessage
 */
const ResponseChecker = (res, returnUrl = '#', defaultMessage = 'Something Went Wrong!') => {
  if (res?.statusCode === 200 && res.data.status) {
    Swal.fire({
      icon: 'success',
      text: res.data.message,
      timer: 2500,
      showConfirmButton: true
    }).then(function () {
      goToDirectory(returnUrl)
    })
  } else if (res?.statusCode === 422) {
    /*let errors = res.data.errors;

        errors.map((err, index)=> {
          console.log("index", index)
        })*/

    Swal.fire({
      icon: 'error',
      text: res.data.message,
      showConfirmButton: true
    })
  } else {
    Swal.fire({
      icon: 'error',
      text: res?.data?.message || defaultMessage,
      showConfirmButton: true
    })
  }
}

const getTimeDifference = (startTime, endTime) => {
  return dayjs(endTime).diff(dayjs(startTime), 'day')
}

const showAddress = (address1, address2) => {
  if (address1 && address2) {
    return `${address1}, ${address2}`
  } else if (address1) {
    return `${address1}`
  } else if (address2) {
    return `${address2}`
  }
}

const showContactInfo = (email, mobile) => {
  if (email && mobile) {
    return `${email}, ${mobile}`
  } else if (email) {
    return `${email}`
  } else if (mobile) {
    return `${mobile}`
  }
}

/*
 * !Notice: Don't change this method without discussion Ahmed & Shafiq
 * */

export const paymentCalculation = (total, adjustmentPercentage, paymentOptions = {}) => {
  let percentage = null
  let adjustmentValue = null
  let total_after_partial = Number(total)
  let total_after_tips = 0
  let totalChargeAmount = null
  let adjustmentSign = ''

  const splitAmount = paymentOptions?.split_payment?.split?.amount?.raw
  const payInAmount = paymentOptions?.split_payment?.pay_in?.amount?.raw

  /* const split_or_pay_in_payment = splitAmount
    ? Number(splitAmount).toFixed(2)
    : payInAmount
    ? Number(payInAmount).toFixed(2)
    : 0*/

  const split_or_pay_in_payment = splitAmount ? splitAmount : payInAmount ? payInAmount : 0

  const selected_tips = paymentOptions?.selected_tips
  const tips_amount = selected_tips && paymentOptions?.tips_options?.[selected_tips]?.amount?.raw

  total_after_tips = Number(total_after_partial) + (Number(tips_amount) || 0)
  // console.log({ selected_tips, tips_amount })

  /*const split_with_tips =
    split_amount && tips_amount
      ? Number(split_amount + tips_amount)
      : split_amount && !tips_amount
      ? split_amount
      : !split_amount && tips_amount
      ? tips_amount
      : 0*/

  // return false

  const total_with_split = Number(split_or_pay_in_payment) > 0 ? Number(split_or_pay_in_payment) : Number(total)
  const valid_tips_amount = Number(tips_amount) > 0 ? Number(tips_amount) : 0

  const totalPayableWithoutCharge = rn(total_with_split + valid_tips_amount)

  if (adjustmentPercentage.includes('-')) {
    adjustmentSign = '-'
    percentage = Number(adjustmentPercentage?.replace('-', ''))
    adjustmentValue = rn((Number(totalPayableWithoutCharge) * percentage) / 100)
    totalChargeAmount = rn(Number(totalPayableWithoutCharge) - Number(adjustmentValue))
  } else if (adjustmentPercentage.includes('+')) {
    adjustmentSign = '+'
    percentage = Number(adjustmentPercentage.replace('+', ''))
    adjustmentValue = rn((Number(totalPayableWithoutCharge) * percentage) / 100)
    totalChargeAmount = rn(Number(totalPayableWithoutCharge) + Number(adjustmentValue))
  } else {
    percentage = Number(adjustmentPercentage)
    adjustmentValue = rn((Number(totalPayableWithoutCharge) * percentage) / 100)
    totalChargeAmount = rn(Number(totalPayableWithoutCharge) + Number(adjustmentValue))
  }

  const net_payable_amount = rn(total) //Genuine amount
  const fee_adjustment_value = rn(adjustmentValue)

  /*  const total_payable_amount =
    Number(tips_amount) > 0
      ? Number(Number(totalChargeAmount) + Number(tips_amount)).toFixed(2)
      : Number(totalChargeAmount).toFixed(2) // Genuine +/- adjustment*/

  const total_payable_amount = rn(totalChargeAmount) // Genuine && +-tips && +/- adjustment

  const due_amount = rn(Number(net_payable_amount) - Number(totalPayableWithoutCharge))

  /*console.table({
    total,
    net_payable_amount,
    fee_adjustment_value,
    total_payable_amount,
    split_or_pay_in_payment,
    totalPayableWithoutCharge,
    tips_amount,
    adjustmentSign,
    due_amount
  })*/

  return {
    total_after_partial,
    total_after_tips,
    net_payable_amount,
    fee_adjustment_value,
    total_payable_amount,
    split_or_pay_in_payment,
    total_payable_without_charge: totalPayableWithoutCharge,
    tips_amount: rn(valid_tips_amount),
    adjustment_sign: adjustmentSign,
    due_amount
  }
}

const customToaster = (toasterType = 'success', toasterMessage = '', toasterPosition = 'top-center', ...rest) => {
  return toast[toasterType](toasterMessage, {
    style: {
      padding: '16px'
    },
    position: toasterPosition,
    ...rest
  })
}

const sendNotification = (iconTye = 'success', message = '', ...rest) => {
  Swal.fire({
    position: 'center',
    icon: iconTye,
    text: message,
    showConfirmButton: true,
    timer: iconTye === 'success' ? 2500 : '',
    timerProgressBar: true,
    showCloseButton: false,
    /*didOpen: () => {
      Swal.showLoading()
    }*/
    ...rest
  })
}

const renderRadioSkeletons = numberOfRadio => {
  let skeletons = []
  for (let i = 0; i < numberOfRadio; i++) {
    skeletons.push(
      <Box sx={{ display: 'flex', gap: '5px' }} key={i}>
        <Skeleton variant='circular' width={25} height={25} />
        <Skeleton width={'100px'} height={25} variant='text' sx={{ fontSize: '1rem' }} />
      </Box>
    )
  }

  return (
    <>
      <Box sx={{ my: 2, display: 'flex', gap: '10px' }}>{skeletons}</Box>
    </>
  )
}

const processEmailAndPhone = type => {
  let processedValue = ''

  let separateEmail = email.split('@')

  if (type === 'email') {
    processedValue = email ? `${separateEmail[0].substring(0, 4)}******@${separateEmail[1]}` : ''
  } else if (type === 'phone') {
    processedValue = phone ? `******@${phone.substring(phone.length - 4)}` : ''
  } else {
    processedValue = ''
  }

  return processedValue
}

const renderDisabledMessages = action => {
  const admin_actions = {
    on_hold: 'This invoice is Hold. Please contact with Vendor or Support.',
    admin_reject: 'This invoice is Rejected. Please contact with Vendor or Support.'
  }

  return admin_actions[action] || ''
}

const cryptoSecret = process.env.NEXT_PUBLIC_CRYPTO_SECRET

const encryptValue = value => {
  return AES.encrypt(value, cryptoSecret).toString()
}

const decryptValue = value => {
  return AES.decrypt(value, cryptoSecret).toString(enc.Utf8)
}

const redirectToDashboard = (redirectTo = '') => {
  const redirectPath = redirectTo || process?.env?.NEXT_PUBLIC_MY_ACCOUNT_URL || ''
  if (typeof window !== 'undefined') {
    // const token = typeof window !== 'undefined' ? secureLocalStorage.getItem('ptm_token') : ''
    // const encryptedToken = encodeURIComponent(encryptValue(token))
    // window.location.href = redirectTo || `${redirectPath}?async=${encryptedToken}`
    window.location.href = redirectTo || `${redirectPath}`
  }
}

const setCookie = (cName, cValue, maxAge) => {
  const today = new Date()
  // today.setTime(today.getTime() + maxAge * 1000)
  today.setTime(today.getTime() + maxAge * 1000 * 10000)

  const whenWillExpire = 'expires=' + today.toUTCString()
  let secure = false
  const sameSite = 'strict'

  if (process.env.NEXT_PUBLIC_NODE_ENV === 'production') {
    secure = true
  }

  document.cookie = `${cName}=${cValue}; ${whenWillExpire}; secure=${secure}; sameSite: ${sameSite}`
}

const getCookie = cName => {
  const name = cName + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const numberOfCookies = decodedCookie.split(';')

  for (let i = 0; i < numberOfCookies.length; i++) {
    let eachCookie = numberOfCookies[i]
    while (eachCookie.charAt(0) === ' ') {
      eachCookie = eachCookie.substring(1)
    }
    if (eachCookie.indexOf(name) === 0) {
      return eachCookie.substring(name.length, eachCookie.length)
    }
  }

  return ''
}

const removeCookies = cookies => {
  cookies.forEach(name => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; Path=/`
  })
}

function setEncryptedCookie(cName, value, maxAge = 1800) {
  const secret = process.env.NEXT_PUBLIC_CRYPTO_SECRET || 'your-secret-key' // Replace with your own secret key

  const stringValue = JSON.stringify(value)
  const encryptedValue = AES.encrypt(stringValue, secret).toString()

  const today = new Date()
  // today.setTime(today.getTime() + maxAge * 1000)
  today.setTime(today.getTime() + maxAge * 1000 * 10000)

  const whenWillExpire = 'expires=' + today.toUTCString()
  let secure = false
  const sameSite = 'None'

  if (process.env.NEXT_PUBLIC_NODE_ENV === 'production') {
    secure = true
  }

  document.cookie = `${cName}=${encryptedValue}; ${whenWillExpire}; secure=${secure}; sameSite: ${sameSite}; Path=/`
}

function getEncryptedCookie(name) {
  const secret = process.env.NEXT_PUBLIC_CRYPTO_SECRET || 'your-secret-key' // Replace with your own secret key

  const value = getMatchedCookie(name)
  if (value === null) {
    return null
  }

  try {
    const bytes = AES.decrypt(value, secret)
    const decryptedValue = bytes.toString(enc.Utf8)

    return JSON.parse(decryptedValue)
  } catch {
    return value
  }
}

function getMatchedCookie(name) {
  const cookieMatch = document.cookie.match(`(^|;)\\s*${name}=([^;]+)`)

  return cookieMatch ? cookieMatch[2] : null
}

const scrollToDiv = id => {
  if (typeof window !== 'undefined') {
    const element = document.getElementById(id)

    window.scrollTo({
      top: element?.getBoundingClientRect().top,
      behavior: 'smooth'
    })
  }
}

const formatPhoneNumber = phoneNumberString => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '')

  return '+' + cleaned.substring(0, 3) + ' ' + cleaned.substring(3, 7) + ' - ' + cleaned.slice(7)
}

const formatPhoneNumberForUs = phoneNumberString => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '')

  const forTenDigit = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)

  const forElevenDigit = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{5})$/)

  if (forTenDigit) {
    return ['+1 ', '(', forTenDigit[2], ') ', forTenDigit[3], ' - ', forTenDigit[4]].join('')
  } else if (forElevenDigit) {
    return ['+1 ', '(', forElevenDigit[2], ') ', forElevenDigit[3], ' - ', forElevenDigit[4]].join('')
  }

  return phoneNumberString
}

const formatNumberByCountryIso2 = (countryIso2 = 'us', phoneNumberString) => {
  if (!countryIso2) {
    return ''
  }
  if (countryIso2.toLowerCase() === 'us') {
    return formatPhoneNumberForUs(phoneNumberString)
  } else {
    return formatPhoneNumber(phoneNumberString)
  }
}

const showCityWithZipCode = (city, state, zipCode, country) => {
  const stateName = state?.s_code ? state.s_code : state?.state || ''

  if (city && stateName && zipCode && country) {
    return `${city}, ${stateName} ${zipCode}, ${country?.iso2 || ''}`
  } else if (city && stateName && zipCode) {
    return `${city}, ${stateName} ${zipCode}`
  } else if (city && stateName) {
    return `${city}, ${stateName}`
  } else if (city && zipCode) {
    return `${city}, ${zipCode}`
  } else if (stateName && zipCode) {
    return `${stateName}, ${zipCode}`
  } else if (city) {
    return city
  } else if (zipCode) {
    return zipCode
  } else if (stateName) {
    return stateName
  }
}

export const Helpers = {
  ResponseChecker,
  getTimeDifference,
  showAddress,
  showCityWithZipCode,
  showContactInfo,
  paymentCalculation,
  customToaster,
  sendNotification,
  renderRadioSkeletons,
  processEmailAndPhone,
  renderDisabledMessages,
  encryptValue,
  decryptValue,
  redirectToDashboard,
  setCookie,
  getCookie,
  removeCookies,
  setEncryptedCookie,
  getEncryptedCookie,
  scrollToDiv,
  formatNumberByCountryIso2
}

export default Helpers
