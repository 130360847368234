import { all, takeEvery } from 'redux-saga/effects'
import * as ACTION from '../constants/actionTypes'

function* updateUserWatcher() {
  yield takeEvery(ACTION.SAMPLE_MIDDLEWARE, attemptUpdateUser)
}

function* attemptUpdateUser() {
  try {
    let response = null
    console.log(response)
  } catch (error) {
    console.log(error)
  }
}

export default function* contactMiddleware() {
  yield all([updateUserWatcher()])
}
