export const defaultPaymentMethodData = [
  {
    info: '',
    name: 'debit_card',
    title: 'Debit Card',
    value: '0',
    status: 0,
    adjustment_amount: 0,
    net_payable_amount: 0
  },
  {
    info: '',
    name: 'credit_card',
    title: 'Credit Card',
    value: '0',
    status: 0,
    adjustment_amount: 0,
    net_payable_amount: 0
  },
  {
    info: '',
    name: 'bank',
    title: 'Bank Transfer',
    value: '0',
    status: 0,
    adjustment_amount: 0,
    net_payable_amount: 0
  }
]
