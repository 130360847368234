import {
  CompanyInfo,
  CustomerBillToForm,
  CustomerDetails,
  CustomerEditableFormData,
  CustomerShipToData,
  LogoOrNameInfo
} from '@/types/global'
import dayjs from 'dayjs'
import { v4 as uuid_v4 } from 'uuid'

const currentDate = dayjs(new Date()).add(0, 'days').format('YYYY-MM-DD')

/*Registration*/
const registerValues = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  phone: '',
  terms: false
}

const PhoneInfo = { iso2: 'us', phone_number: '', phone_with_code: '1' }

const country = {
  country_id: 226,
  name: 'United States (USA)',
  iso2: 'US',
  iso3: 'USA',
  calling_code: '1'
}

const state = {
  state_id: 0,
  state: '',
  s_code: ''
}

const cardInfo = {
  card_number: '',
  card_expiry: {
    month: '',
    year: ''
  },
  cvv: '',
  name_on_card: '',
  email: '',
  phone: '',
  name: ''
}

const billingInfo = {
  city: '',
  country: country,
  line1: '',
  line2: '',
  postal_code: '',
  state: state
}

const bankAccountInfo = {
  bank_name: '',
  routing_number: '',
  account_number: ''
}
const bankAddress = {
  country: country,
  state: state
}

const commonInfo = {
  to_name: '',
  country_iso2: '',
  address_line_1: '',
  address_line_2: '',
  city: '',
  state_id: '',
  zip_code: ''
}

const billToValues: CustomerBillToForm = {
  ...commonInfo,
  type: 'bill'
}

const shipToValues: CustomerShipToData = {
  ...commonInfo,
  attention_to: '',
  type: 'ship'
}

const customerDetails: CustomerDetails = {
  full_name: '',
  email: '',
  phone_number: ''
}

const customerEditableValues: CustomerEditableFormData = {
  id: '',
  visible_name: '',
  to_name: '',
  attention_to: '',
  country_iso2: country,
  address_line_1: '',
  address_line_2: '',
  city: '',
  state_id: state,
  zip_code: '',
  type: 'bill'
}

const LogoOrName: LogoOrNameInfo = {
  invoiceOption: 'invoice-only',
  type: '',
  logosOrNames: [],
  selectedLogo: null,
  selected_option: null,
  selectedName: null,
  openAddNewLogo: false,
  openAddNewName: false,
  company_name_1: '',
  company_name_2: '',
  logo_nick_name: '',
  company_nick_name: '',
  logo_as_default: false,
  name_as_default: false
}

const display_options = {
  company_name: {
    in_detail: true,
    on_invoice: true
  },
  country: {
    in_detail: false,
    on_invoice: false
  },
  address_line_1: {
    in_detail: false,
    on_invoice: false
  },
  address_line_2: {
    in_detail: false,
    on_invoice: false
  },
  state: {
    in_detail: false,
    on_invoice: false
  },
  zip_code: {
    in_detail: false,
    on_invoice: false
  },
  city: {
    in_detail: false,
    on_invoice: false
  },
  website: {
    in_detail: false,
    on_invoice: false
  },
  email: {
    in_detail: false,
    on_invoice: false
  },
  phone: {
    in_detail: false,
    on_invoice: false
  }
}

const companyAddress: CompanyInfo = {
  nick_name: '',
  company_dba: '',
  address_line_1: '',
  address_line_2: '',
  city: '',
  zip_code: '',
  website: '',
  email: '',
  phoneInfo: { iso2: 'us', phone_number: '', phone_with_code: '1' },
  selected_option: null,
  open_add_new: false,
  country: country,
  state: state,
  display_options: display_options
}

const paymentTerm = {
  id: 1,
  name: 'None',
  uuid: '',
  short_code: 'net_none',
  value: '',
  effective_interest: 'None',
  explanation: 'None'
}

const RegularSettings = {
  invoiceNumber: '',
  invoiceTempId: '',
  invoiceDate: currentDate,
  dueDate: currentDate,
  first_invoice_date: currentDate,
  paymentTermValue: '',
  poNumbers: '',
  invoiceTemplate: 'standard',
  isPartial: false,
  messageOnInvoice: '',
  invoice_type: 'regular',
  currencies: {
    options: [],
    selected_currency: {
      id: '',
      uuid: '',
      title: 'US Dollar ($)',
      symbol: '$',
      iso: 'USD'
    }
  },
  invoice_frequencies: {
    options: [],
    selected_frequency: null
  },
  sales_tax_rates: {
    options: [],
    selected_tax_rate: null
  },
  invoice_quantities: {
    options: [],
    selected_quantity: null
  },
  payment_terms: {
    options: [],
    selected_term: paymentTerm
  },
  customer_messages: {
    options: [],
    selected_message: null
  },
  invoice_terms: {
    options: [],
    selected_term: null
  },
  templates: [],
  logo_or_name: {
    selected_option: null
  },
  company_from: {
    options: [],
    selected_option: null,
    open_add_new: false
  },
  customers: {
    options: [],
    selected_customer: null,
    open_add_new: false,
    open_ship_to: false,
    open_edit: false
  },
  customers_bill_address: {
    selected_address: null,
    openAddNew: false
  },
  customers_ship_address: {
    selected_address: null,
    add_ship_to: false,
    same_as_bill_to: false,
    openAddNew: false
  },
  customers_person: {
    selected_address: null,
    openAddNew: false
  },
  id_term_currency: {
    payment_term_id: null,
    currency_id: null,
    invoice_number_format: ''
  },
  attachments: [],
  company_stamp: {
    unique_attachment_id: '',
    name: '',
    extension: '',
    public_link: '',
    url: '',
    openAddNew: false
  },
  signature: {
    unique_attachment_id: '',
    name: '',
    extension: '',
    public_link: '',
    url: '',
    openAddNew: false
  },
  status: 'draft',
  operation_mode: 'create'
}

const InvoiceTemplates = {
  template_type: 'blank',
  default_templates: [],
  blank_templates: [],
  selected_template: null
}
const InvoiceSelectedTemplate = {
  id: '',
  name: '',
  properties: {
    page_size: '',
    preview_url: null
  }
}

const companyForm = {
  nick_name: '',
  company_dba: '',
  address_line_1: '',
  address_line_2: '',
  phone_number: '',
  city: '',
  zip_code: '',
  website: '',
  email: '',
  selected_country: country,
  selected_state: state
}

const splitPayment = {
  partial: false,
  split: false,
  pay_in: false,
  pay_in_value: '+4'
}

const paymentsTips = {
  percent_1st: false,
  percent_2nd: false,
  custom: false,
  percent_1st_value: '5',
  percent_2nd_value: '10'
}

const INVTemplateInfo = {
  name: '',
  invoiceOptions: 'invoice_only',
  options: [],
  selectedOption: null
}

const messageToCustomer = {
  messageName: '',
  options: [],
  selectedOption: null,
  message: '',
  openAddNew: false,
  as_default: false
}

const invoiceTerms = {
  options: [],
  selectedOption: null,
  name: '',
  terms: '',
  openAddNew: false,
  as_default: false
}

const salesTaxInfo = {
  as_default: false,
  addTax: false,
  openAddNew: false,
  name: '',
  value: '',
  options: [],
  selectedOption: null
}

const ContactInfo = {
  id: 1,
  uuid: '',
  bill_to: {
    id: '',
    uuid: '',
    openAddNew: false
  },
  ship_to: {
    id: '',
    uuid: '',
    openAddNew: false,
    add_ship_to: false,
    same_as_bill_to: false
  },
  person: {
    id: '',
    uuid: '',
    openAddNew: false
  }
}

const InvoiceItemsDefault = {
  itemId: uuid_v4(),
  uuid: uuid_v4(),
  service_date: null,
  name: '',
  item_description: '',
  quantity: '',
  unit_price: '',
  taxable: 0,
  item_wise_tax: {
    id: '',
    uuid: '',
    name: '',
    visible_name: '',
    short_name: '',
    rate: ''
  },
  tax_rate: 0,
  total_amount: 0,
  visibility: 'hidden'
}

const invoiceCalculationInit = {
  salesTax: '',
  salesTaxName: '',
  salesTaxRate: '',
  hasTaxExempt: false,
  addCredit: '',
  active_discount_field: '',
  addDiscount: '',
  addDiscountPercentage: '',
  subtotal: 0,
  discount: 0,
  total_tax: 0,
  credit: 0,
  grand_total: 0,
  invoice_message: '',
  terms_and_conditions: 1,
  status: 'draft'
}

const billShipCheckDefault = {
  id: '',
  uuid: '',
  to_name: '',
  visible_name: '',
  attention_to: '',
  country_iso2: '',
  address_line_1: '',
  address_line_2: '',
  country: country,
  state: state,
  city: '',
  state_id: '',
  zip_code: '',
  type: 'bill'
}

const billAndShipValues = {
  to_name: '',
  address_line_1: '',
  address_line_2: '',
  city: '',
  zip_code: '',
  openAddNew: false,
  as_default: true,
  openAddNewCustomer: false,
  state: state,
  country: country
}

const BillToValues = {
  ...billAndShipValues,
  type: 'bill'
}

const ShipToValues = {
  ...billAndShipValues,
  type: 'ship',
  services: [],
  selectedService: null,
  openForm: false,
  add_ship_to: false,
  same_as_bill_to: false
}

const EmailAndPhoneInfo = {
  openAddNewCustomer: false,
  customers: [],
  selectedCustomer: null,
  phoneInfo: {
    iso2: 'us',
    phone_number: '',
    phone_with_cod: ''
  },
  full_name: '',
  email: '',
  as_default: false
}

const ChargeInformation = {
  cardNumberComplete: false,
  expiredComplete: false,
  cvcComplete: false,
  name_on_card: '',
  email: '',
  billing_city: '',
  billing_country_iso2: 'us',
  state_id: '',
  billing_street_address: '',
  billing_street_address2: '',
  billing_zip_code: '',
  phone_number: '1'
}

const cardVerificationsForm = {
  cardNumberComplete: false,
  expiredComplete: false,
  cvcComplete: false,
  name_on_card: '',
  type: ''
}

const DefaultValues = {
  state,
  country,
  PhoneInfo,
  BillToValues,
  ShipToValues,
  registerValues,
  display_options,
  EmailAndPhoneInfo,
  cardInfo,
  billingInfo,
  bankAccountInfo,
  bankAddress,
  billToValues,
  shipToValues,
  customerDetails,
  customerEditableValues,
  ContactInfo,
  LogoOrName,
  companyAddress,
  RegularSettings,
  InvoiceTemplates,
  InvoiceSelectedTemplate,
  splitPayment,
  paymentsTips,
  companyForm,
  cardVerificationsForm,
  INVTemplateInfo,
  messageToCustomer,
  paymentTerm,
  invoiceTerms,
  salesTaxInfo,
  InvoiceItemsDefault,
  invoiceCalculationInit,
  billShipCheckDefault,
  ChargeInformation
}

export default DefaultValues
