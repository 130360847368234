export const SAMPLE_MIDDLEWARE = 'SAMPLE_MIDDLEWARE'

// Customer Action Types

export const STORE_CUSTOMERS = 'STORE_CUSTOMERS'

export const STORE_SINGLE_CUSTOMER = 'STORE_SINGLE_CUSTOMER'

export const STORE_ADJUSTMENT_VALUES = 'STORE_ADJUSTMENT_VALUES'

/*Add Payment Link*/
export const STORE_PAYMENT_METHODS = 'STORE_PAYMENT_METHODS'

export const STORE_SPLIT_PAYMENTS = 'STORE_SPLIT_PAYMENTS'

export const STORE_TIPS = 'STORE_TIPS'

export const RESET_CUSTOMER_REDUCER = 'RESET_CUSTOMER_REDUCER'

// Invoice Action Types

export const STORE_REGULAR_SETTINGS = 'STORE_REGULAR_SETTINGS'

export const STORE_INVOICE_ERRORS = 'STORE_INVOICE_ERRORS'

export const STORE_ITEMS = 'STORE_ITEMS'
export const STORE_SINGLE_ITEM = 'STORE_SINGLE_ITEM'

export const STORE_CALCULATIONS = 'STORE_CALCULATIONS'

export const STORE_INVOICES = 'STORE_INVOICES'

export const STORE_SELECTED_TEMPLATE = 'STORE_SELECTED_TEMPLATE'

export const STORE_EACH_TEMPLATE = 'STORE_EACH_TEMPLATE'

export const RESET_REGULAR_SETTINGS = 'RESET_REGULAR_SETTINGS'

export const RESET_INVOICE_REDUCER = 'RESET_INVOICE_REDUCER'

// Invoice Create Templates
export const STORE_INVOICE_TEMPLATES = 'STORE_INVOICE_TEMPLATES'

export const STORE_TEMPLATE_INFO = 'STORE_TEMPLATE_INFO'
