import {
  RESET_REGULAR_SETTINGS,
  STORE_CALCULATIONS,
  STORE_EACH_TEMPLATE,
  STORE_INVOICE_TEMPLATES,
  STORE_INVOICES,
  STORE_ITEMS,
  STORE_SINGLE_ITEM,
  STORE_REGULAR_SETTINGS,
  STORE_SELECTED_TEMPLATE,
  STORE_INVOICE_ERRORS,
  STORE_TEMPLATE_INFO,
  RESET_INVOICE_REDUCER
} from '../constants/actionTypes'
import DefaultValues from '@/constants/default-values/DefaultValues'

export const invoiceInitialState = {
  regularInvoiceSettings: { ...DefaultValues.RegularSettings },
  invoice_templates: { ...DefaultValues.InvoiceTemplates },
  invoiceItems: [DefaultValues.InvoiceItemsDefault],
  calculations: DefaultValues.invoiceCalculationInit,
  invoices: [],
  template_info: { type: 'invoice_only', init: {} },
  template: [DefaultValues.InvoiceSelectedTemplate],
  each_template: null,

  invoiceErrors: {}
}

const invoiceReducer = (state = invoiceInitialState, action) => {
  switch (action.type) {
    case STORE_REGULAR_SETTINGS:
      return {
        ...state,
        regularInvoiceSettings: action.payload
      }

    case STORE_INVOICE_ERRORS:
      return {
        ...state,
        invoiceErrors: action.payload
      }

    case STORE_ITEMS:
      return {
        ...state,
        invoiceItems: action.payload
      }

    case STORE_SINGLE_ITEM:
      const { index, newValue } = action.payload
      const updatedInvoiceItems = [...state.invoiceItems]
      updatedInvoiceItems[index] = { ...updatedInvoiceItems[index], ...newValue }

      return {
        ...state,
        invoiceItems: updatedInvoiceItems
      }

    case STORE_CALCULATIONS:
      return {
        ...state,
        calculations: action.payload
        // calculations: [DefaultValues.invoiceCalculationInit]
      }

    case STORE_INVOICES:
      return {
        ...state,
        invoices: action.payload
      }

    case STORE_SELECTED_TEMPLATE:
      return {
        ...state,
        template: action.payload
      }

    case STORE_EACH_TEMPLATE:
      return {
        ...state,
        each_template: action.payload
      }

    case STORE_INVOICE_TEMPLATES:
      return {
        ...state,
        invoice_templates: {
          ...state.invoice_templates,
          ...action.payload
        }
      }

    case STORE_TEMPLATE_INFO:
      return {
        ...state,
        template_info: {
          ...state.template_info,
          ...action.payload
        }
      }

    case RESET_REGULAR_SETTINGS:
      return { ...state, regularInvoiceSettings: DefaultValues.RegularSettings }

    case RESET_INVOICE_REDUCER:
      return invoiceInitialState

    default:
      return state
  }
}

export default invoiceReducer
