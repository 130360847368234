import * as React from 'react'
import { SvgProps } from '@/types/global'

export function SpinnerIcon(props: SvgProps) {
  return (
    <svg
      data-name='Spinner Logo'
      xmlns='http://www.w3.org/2000/svg'
      width={50}
      height={80}
      viewBox='0 0 67.61 78.43'
      {...props}
    >
      <g fill='#0600f9'>
        <path d='M64.82 23.15c-1.86-3.36-4.55-6-8.08-7.9s-7.79-2.85-12.8-2.85H21.98v4.77h-4.09v6.58H51v4.31H17.89v4.05H51v14.56H17.89v31.76h13.96V57.02h11.74c5.07 0 9.4-.94 12.98-2.81s6.31-4.48 8.21-7.83c1.89-3.35 2.84-7.22 2.84-11.61s-.93-8.26-2.79-11.62zM25.54 6.02h4.68v4.68h-4.68zM10.96 0h3.6v3.6h-3.6zM0 9h6.78v6.78H0z' />
        <path d='M10.96 6.02h9.25v9.25h-9.25zm-1.9 16.11h5.93v5.93H9.06z' />
      </g>
    </svg>
  )
}
