import React from 'react'
import { SvgProps } from '@/types/global'

export function CalendarIcon(props: SvgProps) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='15' viewBox='0 0 16 15' fill='none' {...props}>
      <path
        d='M0 14.1418V2.07852C0.0155812 2.03237 0.0317616 1.98623 0.0467435 1.93979C0.175588 1.53907 0.503991 1.26577 0.929177 1.25416C1.4928 1.23867 2.05701 1.25148 2.62123 1.25297C2.62872 1.25297 2.63621 1.26368 2.65539 1.27827C2.65539 1.32293 2.65539 1.37681 2.65539 1.4304C2.65539 1.89096 2.6446 2.35182 2.66048 2.81178C2.66708 2.99994 2.69584 3.20268 2.77555 3.37029C2.99398 3.82936 3.3937 3.994 3.8917 3.98566C4.60274 3.97375 5.06118 3.52629 5.06328 2.82042C5.06448 2.35003 5.06328 1.87965 5.06328 1.40926C5.06328 1.35716 5.06328 1.30536 5.06328 1.25445H10.3989C10.3989 1.31459 10.3989 1.36758 10.3989 1.42058C10.3989 1.89096 10.3956 2.36135 10.3998 2.83173C10.404 3.31045 10.6141 3.67247 11.0575 3.87461C11.3584 4.01156 11.6832 3.99876 11.9993 3.93892C12.5407 3.83651 12.805 3.51021 12.8086 2.96332C12.8119 2.44828 12.8095 1.93353 12.8095 1.41849C12.8095 1.3652 12.8095 1.31191 12.8095 1.25654C13.3755 1.25654 13.9242 1.25118 14.4722 1.261C14.5819 1.26309 14.696 1.31042 14.7985 1.35776C15.1071 1.50066 15.26 1.76533 15.3418 2.07941V14.1418C15.3088 14.229 15.2833 14.3198 15.2411 14.4023C15.0385 14.7976 14.7017 14.9593 14.2643 14.9581C12.8721 14.9548 11.48 14.9569 10.0882 14.9569C7.09962 14.9569 4.11073 14.959 1.12214 14.9525C0.928278 14.9522 0.723026 14.9149 0.543543 14.8435C0.234017 14.7205 0.0907903 14.4449 0 14.1415L0 14.1418ZM0.993 13.9766H14.3491V5.11041H0.993V13.9766Z'
        fill='#8789FF'
      />
      <path
        d='M3.1441 1.75072C3.1441 1.39943 3.1429 1.04813 3.1441 0.696528C3.1453 0.267823 3.38801 0.0299517 3.82398 0.0281655C3.8887 0.0281655 3.95402 0.0260815 4.01844 0.0323334C4.34835 0.0644863 4.56828 0.313373 4.57098 0.675986C4.57607 1.39853 4.57607 2.12108 4.57098 2.84333C4.56798 3.25685 4.32078 3.49353 3.90458 3.49829C3.82997 3.49918 3.75446 3.49918 3.68075 3.48906C3.36104 3.445 3.15009 3.21427 3.1459 2.89364C3.14051 2.51257 3.1447 2.1315 3.1444 1.75043L3.1441 1.75072Z'
        fill='#8789FF'
      />
      <path
        d='M12.312 1.75149C12.312 2.11261 12.3137 2.47403 12.312 2.83516C12.3096 3.2332 12.0704 3.47256 11.6719 3.47881C11.5922 3.48 11.5119 3.4806 11.4325 3.47464C11.1607 3.4541 10.9309 3.2457 10.901 2.97627C10.8824 2.80985 10.886 2.64045 10.8857 2.47254C10.8842 1.88873 10.8875 1.30462 10.8839 0.720809C10.8824 0.456143 10.9453 0.21857 11.1994 0.0956148C11.4673 -0.0341874 11.754 -0.0329966 12.0198 0.106332C12.2197 0.211127 12.3111 0.39809 12.3117 0.623457C12.3128 0.999467 12.3117 1.37577 12.3123 1.75178L12.312 1.75149Z'
        fill='#8789FF'
      />
      <path
        d='M3.30787 13.0377C3.03849 13.0377 2.76882 13.0389 2.49944 13.0374C2.33075 13.0365 2.29719 13.0041 2.29689 12.8377C2.29569 12.3825 2.29569 11.9273 2.29689 11.4721C2.29719 11.3036 2.32925 11.2699 2.49675 11.2693C3.0358 11.2675 3.57454 11.2699 4.11359 11.2678C4.24933 11.2672 4.31405 11.3193 4.31255 11.4616C4.30836 11.9219 4.30836 12.3822 4.31255 12.8421C4.31375 12.9815 4.25622 13.0407 4.11659 13.0386C3.84722 13.035 3.57754 13.0374 3.30817 13.0377H3.30787Z'
        fill='#8789FF'
      />
      <path
        d='M6.20225 11.2696C6.46174 11.2696 6.72123 11.2681 6.98071 11.2702C7.1548 11.2713 7.18986 11.3071 7.19016 11.4806C7.19106 11.9311 7.19106 12.3812 7.19016 12.8317C7.18986 13.0028 7.15331 13.0386 6.97772 13.0389C6.44886 13.0398 5.9197 13.0398 5.39084 13.0389C5.20806 13.0389 5.17749 13.01 5.17689 12.8361C5.1757 12.3809 5.1757 11.9257 5.17689 11.4705C5.17719 11.3032 5.20866 11.2719 5.37885 11.2707C5.65332 11.2687 5.92779 11.2702 6.20225 11.2702V11.2696Z'
        fill='#8789FF'
      />
      <path
        d='M9.07705 11.2687C9.34163 11.2687 9.60591 11.2708 9.87049 11.2679C10.008 11.2664 10.0682 11.3232 10.0671 11.464C10.0626 11.9243 10.0629 12.3846 10.0671 12.8445C10.0682 12.9856 10.0068 13.0395 9.86899 13.0386C9.32515 13.036 8.78101 13.0363 8.23716 13.0386C8.10442 13.0392 8.04929 12.9824 8.05019 12.8502C8.05378 12.3852 8.05408 11.9198 8.05019 11.4548C8.04899 11.32 8.10922 11.267 8.23896 11.2681C8.51852 11.2702 8.79778 11.2687 9.07735 11.2687H9.07705Z'
        fill='#8789FF'
      />
      <path
        d='M11.9322 13.038C11.6676 13.038 11.4034 13.0389 11.1388 13.0377C10.9659 13.0368 10.9335 13.0068 10.9329 12.8418C10.9317 12.3816 10.9356 11.9213 10.9305 11.4613C10.929 11.3181 10.9914 11.2672 11.1277 11.2678C11.6667 11.2702 12.2055 11.2678 12.7445 11.2693C12.9093 11.2699 12.9453 11.3077 12.9456 11.4727C12.9465 11.9279 12.9465 12.3831 12.9456 12.8383C12.9453 13.0002 12.9075 13.0365 12.7407 13.0374C12.4713 13.0389 12.2016 13.0377 11.9322 13.0377V13.038Z'
        fill='#8789FF'
      />
      <path
        d='M9.04649 8.08009C8.77711 8.08009 8.50774 8.07712 8.23836 8.08128C8.10472 8.08337 8.04929 8.02829 8.05019 7.89551C8.05349 7.43049 8.05378 6.96546 8.05019 6.50044C8.04899 6.36617 8.10652 6.31496 8.23866 6.31556C8.7873 6.31824 9.33623 6.31764 9.88487 6.31615C10.0089 6.31585 10.0674 6.36587 10.0665 6.49329C10.0638 6.96308 10.0641 7.43317 10.0665 7.90325C10.0671 8.02918 10.0104 8.08218 9.88487 8.08099C9.60561 8.07801 9.32605 8.08009 9.04678 8.08009H9.04649Z'
        fill='#8789FF'
      />
      <path
        d='M11.9508 6.31712C12.2202 6.31712 12.4896 6.3195 12.7589 6.31623C12.8914 6.31444 12.9477 6.36982 12.9465 6.50289C12.9429 6.96792 12.9432 7.43295 12.9465 7.89797C12.9474 8.03254 12.886 8.08196 12.755 8.08136C12.2112 8.07838 11.6674 8.07779 11.1238 8.08166C10.9812 8.08285 10.929 8.0248 10.9305 7.88636C10.935 7.4264 10.9353 6.96613 10.9305 6.50617C10.929 6.36237 10.9908 6.31325 11.1283 6.31563C11.4025 6.3204 11.6769 6.31712 11.9514 6.31712H11.9508Z'
        fill='#8789FF'
      />
      <path
        d='M3.2935 8.79349C3.56797 8.79349 3.84244 8.79706 4.11661 8.792C4.25744 8.78932 4.31407 8.84678 4.31317 8.98611C4.30928 9.44607 4.30958 9.90633 4.31317 10.3663C4.31407 10.4964 4.26553 10.558 4.12979 10.5574C3.58116 10.555 3.03222 10.5547 2.48358 10.5574C2.35054 10.558 2.29481 10.5047 2.29601 10.3714C2.2996 9.90633 2.2993 9.44131 2.29601 8.97628C2.29511 8.84321 2.35204 8.78932 2.48538 8.7917C2.75475 8.79617 3.02413 8.79289 3.2935 8.79289V8.79349Z'
        fill='#8789FF'
      />
      <path
        d='M6.16932 10.5562C5.90504 10.5562 5.64046 10.5529 5.37618 10.5577C5.24044 10.56 5.17452 10.5145 5.17602 10.3692C5.18111 9.90419 5.17991 9.43916 5.17662 8.97414C5.17572 8.83897 5.23595 8.79074 5.36749 8.79164C5.91133 8.79491 6.45517 8.79521 6.99872 8.79164C7.14074 8.79074 7.19438 8.84999 7.19318 8.98783C7.18929 9.44303 7.19228 9.89793 7.19138 10.3531C7.19108 10.5252 7.16292 10.5544 6.99242 10.5556C6.71796 10.5574 6.44379 10.5562 6.16932 10.5562Z'
        fill='#8789FF'
      />
      <path
        d='M9.06266 8.7932C9.33203 8.7932 9.60141 8.79678 9.87078 8.79172C10.0113 8.78904 10.0685 8.8456 10.0673 8.98553C10.0634 9.44549 10.0628 9.90575 10.0673 10.3657C10.0688 10.5083 10.0068 10.5583 9.86928 10.5574C9.32544 10.5542 8.7816 10.5545 8.23805 10.5574C8.10531 10.558 8.04928 10.5056 8.05018 10.372C8.05408 9.90694 8.05378 9.44192 8.05018 8.97689C8.04928 8.84352 8.10591 8.78993 8.23895 8.79201C8.51312 8.79648 8.78759 8.7932 9.06206 8.7932H9.06266Z'
        fill='#8789FF'
      />
      <path
        d='M11.9418 10.5562C11.6673 10.5562 11.3929 10.5535 11.1187 10.5574C10.9869 10.5591 10.9293 10.5076 10.9305 10.3728C10.9347 9.90775 10.9344 9.44273 10.9305 8.9777C10.9296 8.84462 10.9839 8.79104 11.1178 8.79163C11.6664 8.79491 12.2154 8.79401 12.764 8.79193C12.8893 8.79133 12.9465 8.84343 12.9459 8.96936C12.9438 9.43945 12.9435 9.90924 12.9459 10.3793C12.9465 10.5067 12.8884 10.5577 12.7643 10.5568C12.4898 10.5544 12.2157 10.5562 11.9412 10.5562H11.9418Z'
        fill='#8789FF'
      />
    </svg>
  )
}

export function RelativeCalendarIcon(props: SvgProps) {
  return (
    <div className={'relative left-4'}>
      <svg xmlns='http://www.w3.org/2000/svg' width='16' height='15' viewBox='0 0 16 15' fill='none' {...props}>
        <path
          d='M0 14.1418V2.07852C0.0155812 2.03237 0.0317616 1.98623 0.0467435 1.93979C0.175588 1.53907 0.503991 1.26577 0.929177 1.25416C1.4928 1.23867 2.05701 1.25148 2.62123 1.25297C2.62872 1.25297 2.63621 1.26368 2.65539 1.27827C2.65539 1.32293 2.65539 1.37681 2.65539 1.4304C2.65539 1.89096 2.6446 2.35182 2.66048 2.81178C2.66708 2.99994 2.69584 3.20268 2.77555 3.37029C2.99398 3.82936 3.3937 3.994 3.8917 3.98566C4.60274 3.97375 5.06118 3.52629 5.06328 2.82042C5.06448 2.35003 5.06328 1.87965 5.06328 1.40926C5.06328 1.35716 5.06328 1.30536 5.06328 1.25445H10.3989C10.3989 1.31459 10.3989 1.36758 10.3989 1.42058C10.3989 1.89096 10.3956 2.36135 10.3998 2.83173C10.404 3.31045 10.6141 3.67247 11.0575 3.87461C11.3584 4.01156 11.6832 3.99876 11.9993 3.93892C12.5407 3.83651 12.805 3.51021 12.8086 2.96332C12.8119 2.44828 12.8095 1.93353 12.8095 1.41849C12.8095 1.3652 12.8095 1.31191 12.8095 1.25654C13.3755 1.25654 13.9242 1.25118 14.4722 1.261C14.5819 1.26309 14.696 1.31042 14.7985 1.35776C15.1071 1.50066 15.26 1.76533 15.3418 2.07941V14.1418C15.3088 14.229 15.2833 14.3198 15.2411 14.4023C15.0385 14.7976 14.7017 14.9593 14.2643 14.9581C12.8721 14.9548 11.48 14.9569 10.0882 14.9569C7.09962 14.9569 4.11073 14.959 1.12214 14.9525C0.928278 14.9522 0.723026 14.9149 0.543543 14.8435C0.234017 14.7205 0.0907903 14.4449 0 14.1415L0 14.1418ZM0.993 13.9766H14.3491V5.11041H0.993V13.9766Z'
          fill='#8789FF'
        />
        <path
          d='M3.1441 1.75072C3.1441 1.39943 3.1429 1.04813 3.1441 0.696528C3.1453 0.267823 3.38801 0.0299517 3.82398 0.0281655C3.8887 0.0281655 3.95402 0.0260815 4.01844 0.0323334C4.34835 0.0644863 4.56828 0.313373 4.57098 0.675986C4.57607 1.39853 4.57607 2.12108 4.57098 2.84333C4.56798 3.25685 4.32078 3.49353 3.90458 3.49829C3.82997 3.49918 3.75446 3.49918 3.68075 3.48906C3.36104 3.445 3.15009 3.21427 3.1459 2.89364C3.14051 2.51257 3.1447 2.1315 3.1444 1.75043L3.1441 1.75072Z'
          fill='#8789FF'
        />
        <path
          d='M12.312 1.75149C12.312 2.11261 12.3137 2.47403 12.312 2.83516C12.3096 3.2332 12.0704 3.47256 11.6719 3.47881C11.5922 3.48 11.5119 3.4806 11.4325 3.47464C11.1607 3.4541 10.9309 3.2457 10.901 2.97627C10.8824 2.80985 10.886 2.64045 10.8857 2.47254C10.8842 1.88873 10.8875 1.30462 10.8839 0.720809C10.8824 0.456143 10.9453 0.21857 11.1994 0.0956148C11.4673 -0.0341874 11.754 -0.0329966 12.0198 0.106332C12.2197 0.211127 12.3111 0.39809 12.3117 0.623457C12.3128 0.999467 12.3117 1.37577 12.3123 1.75178L12.312 1.75149Z'
          fill='#8789FF'
        />
        <path
          d='M3.30787 13.0377C3.03849 13.0377 2.76882 13.0389 2.49944 13.0374C2.33075 13.0365 2.29719 13.0041 2.29689 12.8377C2.29569 12.3825 2.29569 11.9273 2.29689 11.4721C2.29719 11.3036 2.32925 11.2699 2.49675 11.2693C3.0358 11.2675 3.57454 11.2699 4.11359 11.2678C4.24933 11.2672 4.31405 11.3193 4.31255 11.4616C4.30836 11.9219 4.30836 12.3822 4.31255 12.8421C4.31375 12.9815 4.25622 13.0407 4.11659 13.0386C3.84722 13.035 3.57754 13.0374 3.30817 13.0377H3.30787Z'
          fill='#8789FF'
        />
        <path
          d='M6.20225 11.2696C6.46174 11.2696 6.72123 11.2681 6.98071 11.2702C7.1548 11.2713 7.18986 11.3071 7.19016 11.4806C7.19106 11.9311 7.19106 12.3812 7.19016 12.8317C7.18986 13.0028 7.15331 13.0386 6.97772 13.0389C6.44886 13.0398 5.9197 13.0398 5.39084 13.0389C5.20806 13.0389 5.17749 13.01 5.17689 12.8361C5.1757 12.3809 5.1757 11.9257 5.17689 11.4705C5.17719 11.3032 5.20866 11.2719 5.37885 11.2707C5.65332 11.2687 5.92779 11.2702 6.20225 11.2702V11.2696Z'
          fill='#8789FF'
        />
        <path
          d='M9.07705 11.2687C9.34163 11.2687 9.60591 11.2708 9.87049 11.2679C10.008 11.2664 10.0682 11.3232 10.0671 11.464C10.0626 11.9243 10.0629 12.3846 10.0671 12.8445C10.0682 12.9856 10.0068 13.0395 9.86899 13.0386C9.32515 13.036 8.78101 13.0363 8.23716 13.0386C8.10442 13.0392 8.04929 12.9824 8.05019 12.8502C8.05378 12.3852 8.05408 11.9198 8.05019 11.4548C8.04899 11.32 8.10922 11.267 8.23896 11.2681C8.51852 11.2702 8.79778 11.2687 9.07735 11.2687H9.07705Z'
          fill='#8789FF'
        />
        <path
          d='M11.9322 13.038C11.6676 13.038 11.4034 13.0389 11.1388 13.0377C10.9659 13.0368 10.9335 13.0068 10.9329 12.8418C10.9317 12.3816 10.9356 11.9213 10.9305 11.4613C10.929 11.3181 10.9914 11.2672 11.1277 11.2678C11.6667 11.2702 12.2055 11.2678 12.7445 11.2693C12.9093 11.2699 12.9453 11.3077 12.9456 11.4727C12.9465 11.9279 12.9465 12.3831 12.9456 12.8383C12.9453 13.0002 12.9075 13.0365 12.7407 13.0374C12.4713 13.0389 12.2016 13.0377 11.9322 13.0377V13.038Z'
          fill='#8789FF'
        />
        <path
          d='M9.04649 8.08009C8.77711 8.08009 8.50774 8.07712 8.23836 8.08128C8.10472 8.08337 8.04929 8.02829 8.05019 7.89551C8.05349 7.43049 8.05378 6.96546 8.05019 6.50044C8.04899 6.36617 8.10652 6.31496 8.23866 6.31556C8.7873 6.31824 9.33623 6.31764 9.88487 6.31615C10.0089 6.31585 10.0674 6.36587 10.0665 6.49329C10.0638 6.96308 10.0641 7.43317 10.0665 7.90325C10.0671 8.02918 10.0104 8.08218 9.88487 8.08099C9.60561 8.07801 9.32605 8.08009 9.04678 8.08009H9.04649Z'
          fill='#8789FF'
        />
        <path
          d='M11.9508 6.31712C12.2202 6.31712 12.4896 6.3195 12.7589 6.31623C12.8914 6.31444 12.9477 6.36982 12.9465 6.50289C12.9429 6.96792 12.9432 7.43295 12.9465 7.89797C12.9474 8.03254 12.886 8.08196 12.755 8.08136C12.2112 8.07838 11.6674 8.07779 11.1238 8.08166C10.9812 8.08285 10.929 8.0248 10.9305 7.88636C10.935 7.4264 10.9353 6.96613 10.9305 6.50617C10.929 6.36237 10.9908 6.31325 11.1283 6.31563C11.4025 6.3204 11.6769 6.31712 11.9514 6.31712H11.9508Z'
          fill='#8789FF'
        />
        <path
          d='M3.2935 8.79349C3.56797 8.79349 3.84244 8.79706 4.11661 8.792C4.25744 8.78932 4.31407 8.84678 4.31317 8.98611C4.30928 9.44607 4.30958 9.90633 4.31317 10.3663C4.31407 10.4964 4.26553 10.558 4.12979 10.5574C3.58116 10.555 3.03222 10.5547 2.48358 10.5574C2.35054 10.558 2.29481 10.5047 2.29601 10.3714C2.2996 9.90633 2.2993 9.44131 2.29601 8.97628C2.29511 8.84321 2.35204 8.78932 2.48538 8.7917C2.75475 8.79617 3.02413 8.79289 3.2935 8.79289V8.79349Z'
          fill='#8789FF'
        />
        <path
          d='M6.16932 10.5562C5.90504 10.5562 5.64046 10.5529 5.37618 10.5577C5.24044 10.56 5.17452 10.5145 5.17602 10.3692C5.18111 9.90419 5.17991 9.43916 5.17662 8.97414C5.17572 8.83897 5.23595 8.79074 5.36749 8.79164C5.91133 8.79491 6.45517 8.79521 6.99872 8.79164C7.14074 8.79074 7.19438 8.84999 7.19318 8.98783C7.18929 9.44303 7.19228 9.89793 7.19138 10.3531C7.19108 10.5252 7.16292 10.5544 6.99242 10.5556C6.71796 10.5574 6.44379 10.5562 6.16932 10.5562Z'
          fill='#8789FF'
        />
        <path
          d='M9.06266 8.7932C9.33203 8.7932 9.60141 8.79678 9.87078 8.79172C10.0113 8.78904 10.0685 8.8456 10.0673 8.98553C10.0634 9.44549 10.0628 9.90575 10.0673 10.3657C10.0688 10.5083 10.0068 10.5583 9.86928 10.5574C9.32544 10.5542 8.7816 10.5545 8.23805 10.5574C8.10531 10.558 8.04928 10.5056 8.05018 10.372C8.05408 9.90694 8.05378 9.44192 8.05018 8.97689C8.04928 8.84352 8.10591 8.78993 8.23895 8.79201C8.51312 8.79648 8.78759 8.7932 9.06206 8.7932H9.06266Z'
          fill='#8789FF'
        />
        <path
          d='M11.9418 10.5562C11.6673 10.5562 11.3929 10.5535 11.1187 10.5574C10.9869 10.5591 10.9293 10.5076 10.9305 10.3728C10.9347 9.90775 10.9344 9.44273 10.9305 8.9777C10.9296 8.84462 10.9839 8.79104 11.1178 8.79163C11.6664 8.79491 12.2154 8.79401 12.764 8.79193C12.8893 8.79133 12.9465 8.84343 12.9459 8.96936C12.9438 9.43945 12.9435 9.90924 12.9459 10.3793C12.9465 10.5067 12.8884 10.5577 12.7643 10.5568C12.4898 10.5544 12.2157 10.5562 11.9412 10.5562H11.9418Z'
          fill='#8789FF'
        />
      </svg>
    </div>
  )
}
