const GoToDirectory = (url = '', willOpenInNewTab = false) => {
  const openInNewTab = (url, willOpenInNewTab) => {
    const newWindow = window.open(url, willOpenInNewTab ? '_blank' : '_self', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  return openInNewTab(url, willOpenInNewTab)
}

export default GoToDirectory
